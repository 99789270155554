.title {
  animation: 2s anim-lineUp ease-out;
}

.desc {
  animation: 2s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.background-image {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}

.link-project {
  text-decoration: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.btn {
  text-transform: uppercase;
  text-decoration: none;

  text-align: center;
  padding: 10px 25px;
  border-radius: 15px;
  display: flex;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  letter-spacing: 2px;
  transform: translate(-1%, -1%);
  color: white;
}

.btn-resume {
  margin-left: 60px;
}

.btn-project {
  background: linear-gradient(-60deg, white 55%, black 45%);
  margin-top: 25px;
  padding-right: 0;
}

.btn:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn-git-bg::after {
  background-color: black;
}

.btn-resume-bg::after {
  background-color: white;
}

.btn-project-bg::after {
  background: linear-gradient(-60deg, white 55%, black 45%);
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-git-bg {
  background-color: black;
  color: white;
}

.btn-resume-bg {
  background-color: white;
  color: black;
}

.hrLine {
  border: 0;
  height: 2px;
  background-image: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      rgba(255, 251, 251, 0.75),
      rgba(246, 243, 243, 0));
  width: 100%;
  display: flex;
  margin-top: 20px;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.7;
}

.profile-img:hover {
  opacity: 1;
}

/* .profile-img:active {
  transform: scaleY(-1);
} */