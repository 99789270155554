.link {
  text-decoration: none;
}
.shape {
  position: absolute;
  width: 100%;
  height: 350px;
  border-radius: 0 0 100% 100%;
  background-color: black;
  margin-top: -170px;
}
